// import

import {useRouter} from 'next/router';
import {useEffect} from 'react';

// vars

const lastRx = /\/[^/]+$/u;

// hooks

export function useRedirectUp() {
  const router = useRouter();

  useEffect(() => {
    const parent = router.asPath.replace(lastRx, '') || '/';

    if (parent !== router.asPath) {
      router.replace(parent);
    }
  }, [router.asPath]);
}
