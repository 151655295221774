// import

import {useRedirectUp} from 'modules/App';
import {getNoLayout} from 'modules/Layout';

// component

export default function ErrorView() {
  useRedirectUp();

  return null;
}

ErrorView.withLayout = getNoLayout();
